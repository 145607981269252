.pagination {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    border-radius: 8px;
    margin-left: 15px;

    &__link {
        position: relative;
        display: block;
        padding: .5rem .75rem;
        min-width: 39.33px;
        margin-left: -1px;
        line-height: 1.25;
        color: #007bff;
        background-color: #fff;
        border: 1px solid #dee2e6;
        
        &:hover {
            cursor: pointer;
            color: #dee2e6;
            background-color: #007bff;
            border: 1px solid #dee2e6;
        }

        &:focus {
            outline-color: #2d2e6b;
        }
    }

    &__item {
        margin: 2px;
    }

    &__active {
        background-color: #b0d6ff;
        border: 1px solid #dee2e6;
    }
}
.card-wrapper {
    display: inline-block;
}

.card {
    position: relative;
    width: 320px;
    margin: 0 15px 25px;
    border-radius: 5px;
    background: #c5c5c5;
    padding: 23px;
    text-align: left;
    font-size: .9rem;

    &__name {
        font-weight: 700;
        font-size: 1.5rem;
        min-height: 78px;

        a:hover {
            text-decoration: underline;
        }

    }

    &__stars {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;

        img {
            max-width: 20px;
            max-height: 20px;
            margin-right: 5px;
        }
    }

    &__commit {
        margin: 8px 0;
        font-size: 1.1rem;
    }

    &__href {
        margin-top: 18px;
    }

    &__link {
        background: #949494;
        padding: 7px 11px;
        border-radius: 9px;
        font-size: 1.11rem;
    }
}
.form {
    margin-top: 25px;

    &__input {
        border: 1px solid #868686;
        border-radius: 10px;
        background: none;
        padding: 7px 15px 7px 20px;

        &:focus {
            border: 1px solid #163752;
            outline: none;
        }
    }

    input[type="text"] {
        font-size: 1.1rem;
    }

    &__button {
        margin-left: 10px;
        background: #EFEFEF;
        border-radius: 12px;
        font-size: 1.15rem;
        padding: 7px 20px;
        border: none;
        transition: .2s linear;
        
        &:hover {
            cursor: pointer;
            background: #E5E5E5;
        }

        &:focus {

            outline: none;
        }
    }
}
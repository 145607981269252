.card-page {
    background: #EFEFEF;
    padding: 22px 150px 22px 150px;
    max-width: 726px;
    border-radius: 33px;
    margin: 110px auto 70px auto;
    position: relative;


    &__author {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__img {
        border-radius: 50%;
        max-width: 88px;
        max-height: 88px;
    }

    &__login {
        margin-left: 12px;
        font-size: 1.6rem;

        &:hover {
            text-decoration: underline;
        }
    }

    &__repo {
        font-weight: bold;
        font-size: 2.3rem;
        text-align: center;
        margin-top: 15px;

        a {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__desc {
        font-size: 1.2rem;
        text-align: center;
        margin: 12px 0;
    }

    &__info, &__contributors {
        font-size: 1.4rem;
        text-align: center;
    }

    &__info > div {
        margin: 9px 0;
    }

    &__top {
        margin-top: 40px;
        margin-bottom: 20px;
    }
}
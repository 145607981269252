*, *::after, *::before {
    box-sizing: border-box;
}

html {
    font-size: 15px;
}

body {
    font-family: $fontFamily;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.container {
    max-width: 1790px;
    margin: 0 auto;
    padding: 0 16px;
}

.text-center {
    text-align: center;
}

.bolder {
    font-weight: bold;
}

a {
    text-decoration: none;
    color: inherit;
}

h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
}
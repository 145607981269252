.contributors {

    display: inline-block;
    margin: 0 15px 25px 15px;

    &__img {
        border-radius: 50%;
        max-width: 80px;
        max-height: 80px;
    }

    &__name {

    }
}